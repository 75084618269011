import $ from 'jquery'
import {fixInput} from './functions/forms'

export default class BLK {

  // initialisation des autocompletes
  static initAddressAutocomplete() {

    $('#roadname').autocomplete({
      serviceUrl: '/blanko/address/autocomplete/street',
      appendTo: '#roadname-search-wrapper',
      onSelect: function (suggestion) {
        let civicOptions = $('#nocivic').autocomplete().options.params
        civicOptions.street = suggestion.value
        let civicNewOptions = {
          'params': civicOptions
        }
        $('#nocivic').autocomplete().setOptions(civicNewOptions)
      }
    })

    $('#nocivic').autocomplete({
      serviceUrl: '/blanko/address/autocomplete/civic',
      appendTo: '#nocivic-search-wrapper',
      onSelect: function (suggestion) {
        let streetOptions = $('#roadname').autocomplete().options.params
        streetOptions.civic = suggestion.value
        let streetNewOptions = {
          'params': streetOptions
        }
        $('#roadname').autocomplete().setOptions(streetNewOptions)
      }
    })

  }

  // ***********************
  // Change le ville du plaignant en fonction de celle du demandeur
  // ***********************
  static updatePlaintiffCityOption(element) {
    // recupere la valeur de la ville
    let cityName = $(element).val()
    // recupere la valeur du citoyen
    let cityPlaintiffName = $('#plaintiff_city').val()
    // Si le ville du citoyen est vide, on la met à jour avec celle du demandeur
    if (cityPlaintiffName == null || cityPlaintiffName.length == 0 ) {
      $('#plaintiff_city').val(cityName)
      $('select').trigger('chosen:updated')
      BLK.updateCityOption($('#plaintiff_city'))
    }
  }

  // si changement de ville du citoyen
  static updateCityOption(element) {
    // recupere la valeur de la ville
    let cityName = $(element).val().toLowerCase()
    // ajoute la contrainte aux deux autres filtres
    let cityOptions = {
      'params': {'city': cityName}
    }

    if (cityName) {
      // recupere la valeur de la ville
      $('#plaintiff_city').val($(element).val())
      $('#plaintiff_city').addClass('notEmpty')

      // réinitialise roadname/nocivic quand on change de ville
      BLK.addressReset()
      BLK.addressEnabled()
    } else {
      BLK.addressDisabled()
    }

    $('#roadname').autocomplete().setOptions(cityOptions)
    $('#nocivic').autocomplete().setOptions(cityOptions)

  }

  static success(element, context, textStatus) {
    $(element).trigger('reset') // resetForm
    $('chosen-select').val('')
    $('select').trigger('chosen:updated')

    BLK.formOn(element) // Reactiver le form

    $('#form-success').show()
    // reset forms
    fixInput()
    let event = new Event('blk.success')
    window.dispatchEvent(event)
  }

  static addressReset()
  {
    $('#roadname').val('')
    $('#nocivic').val('')
    $('#roadname').removeClass('notEmpty')
    $('#nocivic').removeClass('notEmpty')
  }

  static addressEnabled() {
    $('#roadname-wrapper').removeClass('disabled')
    $('#nocivic-wrapper').removeClass('disabled')
  }

  static addressDisabled() {
    $('#roadname-wrapper').addClass('disabled')
    $('#nocivic-wrapper').addClass('disabled')
  }

  static formOff(form) {
    $(form).addClass('disabled')
  }

  static formOn(form) {
    $(form).removeClass('disabled')
  }

}
