//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\


// Importation des nodes modules __________


// Importation des scripts __________
import $ from 'jquery'

window.jQuery = $
window.$ = $
import 'jquery.easing'
import 'jquery-validation'
import 'jquery-mask-plugin'
import 'chosen-js'
import 'devbridge-autocomplete'
import {fixInput, multipleFilesUpload} from './functions/forms'
import BLK from './generique'

$(function () {

  $('select').chosen()
  fixInput()
  multipleFilesUpload()
  window.BLK = BLK
  // initalisation autoComplete
  BLK.initAddressAutocomplete()

  // Format de telephone
  $('#plaintiff_phone').mask('000 000-0000')

  // si soucis de validation
  $(window).on('ajaxValidation', function (context, message, fields) {
    //window.grecaptcha.reset()
    BLK.formOn('#formReporting')
  })

  // validation pour chaque champ
  $(window).on('ajaxInvalidField', function (event, fieldElement, fieldName, errorMsg, isFirst) {
    $(fieldElement).closest('section').addClass('has-error')
    return false
  })

  $(document).on('ajaxPromise', '[data-request]', function () {
    $('.has-error').removeClass('has-error')
  })



  window.scrollToError = function () {
    let $errors = $('.has-error')
    if ($errors.length > 0) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $errors.offset().top
      }, 1500, 'easeInOutQuart')
    }
  }

  // Démarrage des scripts __________
  window.addEventListener('DOMContentLoaded', () => {
    setTimeout(function () {
      $('html').removeClass('chargement')
    }, 100)

    $('body').keydown(function (e) {
      var keycode = (e.keyCode ? e.keyCode : e.which)
      if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
        location = '/administration'
        return false
      }
    })

    console.log($('#form-success'))
    $('#form-success').hide()


    window.onSubmit = function (targetForm) {
      BLK.formOff(targetForm)
    }

    function buttonOff(form) {
      $(':submit', form).attr('disabled', true)
    }

    function buttonOn(form) {
      $(':submit', form).removeAttr('disabled')
    }

  })
})
