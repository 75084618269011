//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import anime from 'animejs/lib/anime.es'
import { OBSERVER } from '../plugins'
import { formatBytes } from './helper'
//-----------------------------------------------------------------------------------------------------------------

// LES FONCTIONS
// Fonction qui gère l'ajout de multiple fichier dans un input files
export function multipleFilesUpload() {
  const makeid = () => {
    console.log('1')
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const charactersLength = characters.length
    for (let i = 0; i < 10; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const deleteItem = (e) => {
    console.log('2')
    anime.timeline({
      targets: e.currentTarget.parentNode.parentNode.parentNode,
      duration: 300,
      easing: 'easeOutCubic',
    }).add({
      height: '0px',
      opacity: 0,
    }).finished.then(() => {
      e.target.parentNode.parentNode.parentNode.parentNode.remove()
    })
  }

  const addItem = (e) => {
    console.log('3')
    const originalID = e.currentTarget.id
    const originalName = e.currentTarget.name
    const inputContainer = e.currentTarget.parentNode
    const containerList = e.currentTarget.parentNode.parentNode.parentNode.querySelector('.js-multiple-files-field-files-list')
    const template = e.currentTarget.parentNode.parentNode.querySelector('.js-multiple-files-field-file-block-template').content.cloneNode(true)

    if ($(e.currentTarget).get(0).files.length > 0) {
      const id = makeid()
      const file = $(e.currentTarget).get(0).files[$(e.currentTarget).get(0).files.length - 1]

      let previewSrc = '/themes/assets/medias/images/default/multiple-files-field-preview-default.png'
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        previewSrc = URL.createObjectURL(file)
      }

      template.querySelector('.js-multiple-files-field-file-image').src = previewSrc
      template.querySelector('.js-multiple-files-field-file-title').textContent = `${file.name}`
      template.querySelector('.js-multiple-files-field-file-size').textContent = `${formatBytes(file.size)}`
      template.querySelector('.js-multiple-files-field-file-instance').setAttribute('id', `container-${id}`)
      containerList.appendChild(template)
      e.currentTarget.setAttribute('id', id)
      document.querySelector(`#container-${id}`).appendChild(e.currentTarget)
      const height = `${document.querySelector(`#${id}`).parentNode.querySelector('.js-multiple-files-field-file-height').clientHeight}px`

      anime.timeline({
        targets: document.querySelector(`#${id}`).parentNode,
        duration: 300,
        easing: 'easeOutCubic',
      }).add({
        height,
        opacity: 1,
      }).add({
        height: '100%',
      })
    }

    const newInput = document.createElement('input')
    newInput.setAttribute('class', 'multiple-files-field__button-input || js-multiple-files-field-button-input')
    newInput.setAttribute('type', 'file')
    newInput.setAttribute('name', originalName)
    newInput.setAttribute('id', originalID)
    newInput.setAttribute('accept', '.pdf, .jpg, .png')

    inputContainer.appendChild(newInput)

    OBSERVER.off('multipleFilesUpload')
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'change',
      targets: 'input[type=file].js-multiple-files-field-button-input',
      function: addItem,
    })
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'click',
      targets: '.js-multiple-files-field-file-delete',
      function: deleteItem,
    })
    OBSERVER.on('multipleFilesUpload')
  }

  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'change',
    targets: 'input[type=file].js-multiple-files-field-button-input',
    function: addItem,
  })
  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'click',
    targets: '.js-multiple-files-field-file-delete',
    function: deleteItem,
  })
  OBSERVER.on('multipleFilesUpload')
}


// Fonction qui gère le label des input
export function fixInput() {
  $('input, textarea').each(function() {
    // ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
    ( $(this).val() != '' || ( typeof $(this).attr('placeholder') !== 'undefined' && $(this).attr('placeholder') != '' )  ) ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty')
  })

  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input, textarea').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, et ne contient pas de placholder on replace le label à sa position d'origine au focusout
  $('input, textarea').focusout(function() {
    // ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
    ( $(this).val() != '' || ( typeof $(this).attr('placeholder') !== 'undefined' && $(this).attr('placeholder') != '' )  ) ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty')
  })
}


// Transforme les selects
export function magicSelect() {
  $('.chosen-select').chosen({ disable_search: true })

  $('.chosen-select').next().click(function() {
    //$('html').toggleClass('showChosenOverlay')
    if($(this).hasClass('chosen-with-drop')){
      $(this).parent().addClass('currentDrop')
    }else{
      $(this).parent().removeClass('currentDrop')
    }

  })

  /*$('.chosenOverlay').click(function() {
    $('html').removeClass('showChosenOverlay')
  })*/
}


// Permet de changer le label des input files
export function changeLabelUpload() {
  $('input[type=file]').off('change')
  $('input[type=file]').on('change', function() {
    $(this).next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function () {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  return {
    required: 'Ce champ est obligatoire.',
    email: 'Veuillez fournir une adresse électronique valide.',
    phone: 'Veuillez fournir un numéro de téléphone valide.',
    same: 'Veuillez fournir la même adresse courriel.',
    keywordslength: 'Entrez un mot-clé d’au moins 3 lettres.'
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

export function formatPostalCode(element) {
  $(element).mask('S0S 0S0')
}

// check si il existe des champs qui necessitent un formatage
// appelé pour chaque page en cas de présence de formulaire
export function checkForMaskerade() {
  formatPhone('.mask-phone')
  formatPostalCode('.mask-postalcode')
}


// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({ ignore: [] })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radioField').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkboxField').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })

  $(submit).click(function() {
    $(form).submit()
  })
}

// Validation du formulaire de recherche par adresse
export function formAdresseSearch() {
  
  var m = getMessages()
  var rules = {
    nocivique:         { required: true },
    rue:               { required: true }
  }
  
  var messages = {
    nocivique:         { required: 'Entrer un no.' },
    rue:               { required: 'Entrer une rue.' }
  }
  
  formValidation('#formAddressSearch', null, rules, messages)
  
  /*
  $('#formAddressSearchBtn').click( function() {
    if ($('#formAddressSearch-number').val() == '') { $('#formAddressSearch-number').focus(); return false }
    if ($('#formAddressSearch-address').val() == '') { $('#formAddressSearch-address').focus(); return false }
    
    $('#formAddressSearch').submit()
  })*/
}

// Validation du formulaire de contact
export function formContact() {
  
  validationEmail()
  formatPhone('#formContact-phone')
  var m = getMessages()
  var rules = {
    prenom:            { required: true },
    nom:               { required: true },
    courriel:          { required: true, email: true },
    courrielConfirm:   { required: true, equalTo: '#formContact-email' },
    telephone:         { required: true, minlength: 12 },
    address:           { required: true },
    type:              { required: true },
    message:           { required: true },
  }
  var messages = {
    prenom:            { required: m.required },
    nom:               { required: m.required },
    courriel:          { required: m.required, email: m.email },
    courrielConfirm:   { required: m.required, equalTo: m.same, email: m.email },
    telephone:         { required: m.required, minlength: m.phone },
    address:         { required: m.required },
    type:              { required: m.required },
    message:           { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages)
}

// Validation du formulaire de contact
export function formSearch(formID,formSubmit) {

  var m = getMessages()
  var rules = {
    q: { required: true, minlength:3 },
  }
  var messages = {
    q: { required: m.required, minlength: m.keywordslength },
  }

  formValidation(formID, formSubmit, rules, messages)
}

// Validation du formulaire de contact
export function formInfolettre(formID,formSubmit) {
  validationEmail()
  var m = getMessages()
  var rules = {
    courriel: { required: true, courriel: true },
  }
  var messages = {
    courriel: { required: m.required, courriel: m.email },
  }

  formValidation(formID, formSubmit, rules, messages)
}

// Reset de validation
export function formSuccess( form, data ) {

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading')
    $('.stripe-loading-indicator').addClass('loaded')
    $(form)[0].reset()
    resetDropzone(form)
    if ( typeof grecaptcha !== 'undefined' ) grecaptcha.reset()
    $.oc.flashMsg({text: 'Le formulaire est bien envoyé !',class: 'success'})
  }

}

// RESET LES DROPZONE DU FORMULAIRE
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  let dropZoneList = $('.fileField',$(formObj))

  // reset des dropzones du form
  dropZoneList.each(function( index, element ) {
    // pour chaque drop zone trouvé dans le form
    // on cherche son instance de Dropzone
    let dropZoneInstance = getDropzone( element.id )
    dropZoneInstance.removeAllFiles()
  })

}

// Retourne la dropZone en fonction de son nom
export function getDropzone( name ) {
  let selectedDropzone = false
  Dropzone.instances.forEach(function (dropzone) {
    if ( dropzone.element.id == name ) selectedDropzone = dropzone
  })
  return selectedDropzone
}
