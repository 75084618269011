//------------------------\\
//--- Fonctions utiles ---\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions utilitaires qu'on a souvent besoin et qui ne changent pas
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Configurer un cookie
export function setCookie(name, value, exdays='') {
  let date = new Date()
  date.setTime(date.getTime() + (exdays*24*60*60*1000))
  let expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

// Retourne le poids d'un fichier
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / (k * i)).toFixed(dm))} ${sizes[i]}`
}


// Recevoir un cookie
export function getCookie(cookie) {
  let name = cookie + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  let i
  for(i=0; i<ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0)
      return c.substring(name.length, c.length)
  }
  return ''
}


// Retourne si l'utilisateur est sur mobile ou pas
export function isMobile() {
  var isMobile = false

  if((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}


// Retourne si l'utilisateur est sur ie
export function isIE() {
  var isIE = false

  if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
    isIE = true

  return isIE
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (error) { console.log(error) }
  }
}


// Fonction permettant de régler les position fixed
export function fixExplorerScroll () {
  
  if(isIE()) {    
    $('body').off('mousewheel')
    $('body').on('mousewheel', function(e) {
      event.preventDefault()
      var wd = event.wheelDelta / 1.5
      var csp = window.pageYOffset
      window.scrollTo(0, csp - wd)
    })
  }
}
